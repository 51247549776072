function Viewport(breakpoints) {

    // Get the breakpoint config
    this.breakpoints = breakpoints;

    // Constuct the breakpoint for the media query
    this.mediaQuery = function(breakpoint) {
        return '(' + this.breakpoints[breakpoint].type + ': ' + this.breakpoints[breakpoint].size + 'px)';
    }

    // Check if the media query matches
    this.matchMedia = function(breakpoint) {
        return window.matchMedia(this.mediaQuery(breakpoint));
    }

    // Check if the media query matches
    this.matches = function(breakpoint) {
        return window.matchMedia(this.mediaQuery(breakpoint)).matches;
    }

    // Construct the custom media query
    this.customMediaQuery = function(breakpoint) {
        return breakpoint;
    }

    // Check if the custom media query matches
    this.customMatchMedia = function(breakpoint) {
        return window.matchMedia(this.customMediaQuery(breakpoint));
    }

    // Check if the custom media query matches
    this.customMatches = function(breakpoint) {
        return window.matchMedia(this.customMediaQuery(breakpoint)).matches;
    }

    // Watch for viewport resize
    this.resize = function(callback) {
        var windowResizing;

        window.addEventListener('resize', function() {
            clearTimeout(windowResizing);
            windowResizing = setTimeout(function() {
                if (typeof callback == 'function') {
                    callback();
                }
            }, 100);
        });
    }
}

;(function (app, viewport) {
    app.viewport = viewport;
})(window.D3RSite = window.D3RSite || {}, new Viewport(D3R.breakpoints));